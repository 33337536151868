import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {useContext, useEffect} from 'react';
import {useFormik} from 'formik';
import authContext from '../../common/hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {
  noMatchError,
  noPatternError,
  passwordRegex,
  requiredError,
} from '../../common/constants';

const CompletePassword = () => {
  const {completePassword, isAuthenticated, user, challengePath} =
    useContext(authContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && challengePath !== '/create-password') {
      navigate('/users');
    }
  }, [challengePath, user, isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async ({password}) => {
      try {
        await completePassword(password);
      } catch (error) {
        console.log(error);
      }
    },
    validate: values => {
      return {
        ...(!values.password && {password: requiredError}),
        ...(values.password &&
          !passwordRegex.test(values.password) && {password: noPatternError}),
        ...(!values.passwordConfirmation && {
          passwordConfirmation: requiredError,
        }),
        ...(values.passwordConfirmation &&
          values.passwordConfirmation !== values.password && {
            passwordConfirmation: noMatchError,
          }),
      };
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Crear contraseña</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  id="password"
                />
                {!!formik.errors.password && formik.touched.password ? (
                  <div style={{color: 'red', fontSize: 'small'}}>
                    {formik.errors.password}
                  </div>
                ) : null}
              </FormControl>
              <FormControl id="password-confirmation">
                <FormLabel>Confirmar contraseña</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  name="passwordConfirmation"
                  id="password-confirmation"
                />
                {!!formik.errors.passwordConfirmation &&
                formik.touched.passwordConfirmation ? (
                  <div style={{color: 'red', fontSize: 'small'}}>
                    {formik.errors.passwordConfirmation}
                  </div>
                ) : null}
              </FormControl>
              <Stack>
                <Stack
                  direction={{base: 'column', sm: 'row'}}
                  align={'start'}
                  justify={'space-between'}
                ></Stack>
                <Button
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                  width="full"
                  type="submit"
                  bg="gray.900"
                  color="white"
                  colorScheme="blackAlpha"
                >
                  Guardar
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default CompletePassword;
