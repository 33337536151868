import React from 'react';
import {
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {container} from '../../container';
import {Types} from '../../types';
import Filters from './Filters';
import Table from './Table';
import {FilterValues} from './interfaces';
import CreateAdminUserModal from './CreateAdminUserModal';
import {AdminUsersRepository} from '../../common/repositories';

const AdminUsers = () => {
  const [filters, setFilters] = React.useState({});
  const {isOpen, onClose, onOpen} = useDisclosure();

  const adminUserService = container.get<AdminUsersRepository>(
    Types.ADMIN_USER_REPOSITORY
  );

  const {isLoading, isFetching, error, data} = useQuery(
    ['adminUsers', filters],
    () => adminUserService.getAll({filters}),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const onFiltersSubmit = (values: FilterValues) => {
    setFilters(values);
  };
  if (isLoading || isFetching) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Spinner />
        </Center>
      </Flex>
    );
  } else if (error) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Heading fontWeight="light" fontSize="3xl">
            Error
          </Heading>
        </Center>
      </Flex>
    );
  } else {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Flex justifyContent="space-between">
          <Filters onSubmit={onFiltersSubmit} />
          <Button
            type="submit"
            bg="gray.900"
            color="white"
            colorScheme="blackAlpha"
            onClick={onOpen}
          >
            Crear usuario
          </Button>
        </Flex>
        <Table adminUsers={data || []} />
        <CreateAdminUserModal onClose={onClose} isOpen={isOpen} />
      </Flex>
    );
  }
};

export default AdminUsers;
