import React, {useState} from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import Filters from './Filters';
import {FilterValues} from './interfaces';
import {container} from '../../container';
import {CollectionRepositoryInterface} from '../../common/repositories/baseRepository.interface';
import {Types} from '../../types';
import {useQuery} from '@tanstack/react-query';
import OnboardingDetail from './OnboardingDetail';
import {UserListResponse} from '../../common/models';
import {User} from '../../models';
import Table from './Table';
import {Pagination} from '../../common/components';

const Onboardings = () => {
  const pageSize = 20;
  const [page, setCurrentPage] = React.useState(1);
  const [filters, setFilters] = useState({});
  const [record, setRecord] = React.useState<User | null>(null);
  const {isOpen, onClose, onToggle} = useDisclosure();

  const onBoardingService = container.get<
    CollectionRepositoryInterface<UserListResponse>
  >(Types.ONBOARDING_REPOSITORY);

  const loadUsers = () => {
    return useQuery(
      ['records', page, filters],
      () => onBoardingService.getAll({page, filters, pageSize}),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: false,
      }
    );
  };

  const {isLoading, isFetching, error, data} = loadUsers();

  const onFiltersSubmit = (values: FilterValues) => {
    setFilters(values);
  };
  const records: Array<User> = data?.items || [];
  const totalCount = data?.total || 0;
  const hasPrevPage = page > 1;
  const hasNextPage = data?.next !== null;

  if (isLoading || isFetching) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Spinner />
        </Center>
      </Flex>
    );
  } else if (error) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Heading fontWeight="light" fontSize="3xl">
            Error
          </Heading>
        </Center>
      </Flex>
    );
  } else {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Filters onSubmit={onFiltersSubmit} />
        <Table records={records} onToggle={onToggle} setRecord={setRecord} />
        <Pagination
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={page}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          setCurrentPage={setCurrentPage}
        />
        {record && (
          <Drawer onClose={onClose} isOpen={isOpen} size="xl">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <Heading fontWeight="light" fontSize="3xl">
                  Detalle OB/Expediente
                </Heading>
              </DrawerHeader>
              <DrawerBody>
                <OnboardingDetail record={record} onClose={onClose} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </Flex>
    );
  }
};

export default Onboardings;
