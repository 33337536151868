export const getStatusColor = (status: string) => {
  const statuses: {[index: string]: string} = {
    default: '',
    blocked: 'red',
    active: 'green',
    suspended: 'red',
  };
  return statuses[status] || statuses['default'];
};

export const getCreditStatusColor = (status: string) => {
  status = status.toUpperCase();
  const successValues = ['PRE-APPROVED', 'APPROVED_ICC', 'APPROVED'];
  const errorValues = ['REJECTED_BY_PRODUCT'];
  if (successValues.includes(status)) {
    return 'green';
  } else if (errorValues.includes(status)) {
    return 'red';
  }
  return 'gray';
};

export const moneyFormat = (value: number) => {
  return value.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });
};
