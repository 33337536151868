import React from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Box,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {CreateAdminUserModalProps} from './interfaces';
import {useFormik} from 'formik';
import {placeHolders, createAdminUserInitialValues} from './constants';
import {container} from '../../container';
import {Types} from '../../types';
import {
  AdminUsersRepository,
  AdminGroupsRepository,
} from '../../common/repositories';

const CreateAdminUserModal = ({isOpen, onClose}: CreateAdminUserModalProps) => {
  const adminUserService = container.get<AdminUsersRepository>(
    Types.ADMIN_USER_REPOSITORY
  );

  const groupService = container.get<AdminGroupsRepository>(
    Types.GROUP_REPOSITORY
  );

  const {data} = useQuery(['groups'], () => groupService.getAll(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const formik = useFormik({
    initialValues: createAdminUserInitialValues,
    onSubmit: async (values, formikHelpers) => {
      try {
        await adminUserService.create(values);
        formikHelpers.resetForm();
        onClose();
      } catch (e) {
        console.log(e);
      }
    },
  });
  return (
    <Modal
      closeOnOverlayClick={true}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Crear usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" width="full">
              <Box flexGrow="1">
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder={placeHolders.email}
                  focusBorderColor="gray.900"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Box>
              <Box>
                <Select
                  name="group_name"
                  id="group_name"
                  placeholder={placeHolders.group_name}
                  onChange={formik.handleChange}
                  value={formik.values.group_name}
                >
                  {data &&
                    data.map(group => (
                      <option key={group.group_name} value={group.group_name}>
                        {group.group_name}
                      </option>
                    ))}
                </Select>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blackAlpha"
              bg="gray.900"
              color="white"
              mr={3}
              isLoading={formik.isSubmitting}
              type="submit"
            >
              Crear
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateAdminUserModal;
