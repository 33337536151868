const placeHolders = {
  email: 'Correo',
  group_name: 'Grupo',
};

const createAdminUserInitialValues = {
  email: '',
  group_name: '',
};

const filtersInitialValues = {
  email: '',
};

export {placeHolders, createAdminUserInitialValues, filtersInitialValues};
