import React from 'react';

import {Flex, HStack, IconButton, Text} from '@chakra-ui/react';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import PaginationRange from './PaginationRange';

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  setCurrentPage: Function;
}

const Pagination = ({
  pageSize,
  currentPage,
  totalCount,
  setCurrentPage,
  hasPrevPage,
  hasNextPage,
}: PaginationProps) => {
  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  const indexOfLastRecord = hasNextPage ? currentPage * pageSize : totalCount;
  const indexOfFirstRecord = (currentPage - 1) * pageSize + 1;
  return (
    <Flex justifyContent="space-between" alignItems="center" paddingY="4">
      <Text fontSize="sm">
        {`Resultados ${indexOfFirstRecord} a ${indexOfLastRecord} de ${totalCount}.`}
      </Text>
      <HStack>
        <IconButton
          disabled={!hasPrevPage}
          onClick={onPrevious}
          variant="ghost"
          aria-label="prev_page"
          icon={<BsChevronLeft />}
        />
        <PaginationRange
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <IconButton
          disabled={!hasNextPage}
          onClick={onNext}
          variant="ghost"
          aria-label="next_page"
          icon={<BsChevronRight />}
        />
      </HStack>
    </Flex>
  );
};

export default Pagination;
