const placeHolders = {
  id: 'ID',
  name: 'Nombres',
  last_name: 'Apellidos',
  phone: 'Teléfono',
  curp: 'CURP',
  email: 'Correo',
};
const initialValues = {
  id: '',
  name: '',
  last_name: '',
  phone: '',
  curp: '',
  email: '',
};
export {placeHolders, initialValues};
