import React from 'react';
import {useToast} from '@chakra-ui/react';
import Moment from 'react-moment';
import {Badge} from '@chakra-ui/react';
import {IconStatus} from './utils';
import {Credit} from '../../models';
import {RecordDetailsProps} from './interfaces';
import {EditableField} from '../../common/components';
import {UpdateIdLinkRequest, UpdateIdLinkResponse} from './models';
import {
  CreateRepositoryInterface,
  OnboardingActionsInterface,
} from '../../common/repositories/baseRepository.interface';
import {BaseActionsResponse} from '../../common/models/baseResponse';
import {container} from '../../container';
import {Types} from '../../types';

export const User = ({record}: RecordDetailsProps) => {
  const toast = useToast();

  const onBoardingService = container.get<
    OnboardingActionsInterface<BaseActionsResponse> &
      CreateRepositoryInterface<UpdateIdLinkRequest, UpdateIdLinkResponse>
  >(Types.ONBOARDING_REPOSITORY);

  const getEvaluationStatus = (evaluations: Array<Credit>) => {
    if (evaluations.length === 0) {
      return IconStatus(null);
    } else {
      const moreRecentEvaluation = evaluations.reduce((prev, current) =>
        prev.createdAt > current.createdAt ? prev : current
      );
      let color = 'gray';
      if (
        moreRecentEvaluation.status.includes('ACEPTADO') ||
        moreRecentEvaluation.status.includes('approved')
      ) {
        color = 'green';
      } else if (
        moreRecentEvaluation.status.includes('RECHAZADO') ||
        moreRecentEvaluation.status.includes('rejected')
      ) {
        color = 'yellow';
      }
      return <Badge colorScheme={color}>{moreRecentEvaluation.status}</Badge>;
    }
  };

  const onSubmitRfc = async (value: string) => {
    onBoardingService
      .updateRfc({
        value: value,
        userId: record.id,
      })
      .then(() => {
        toast({
          title: 'Se actualizo al usuario',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          title: 'Error al actualizar al usuario',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      });
  };
  const onSubmitCurp = async (value: string) => {
    onBoardingService
      .updateCurp({
        value: value,
        userId: record.id,
      })
      .then(() => {
        toast({
          title: 'Se actualizo al usuario',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          title: 'Error al actualizar al usuario',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      });
  };
  return (
    <>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Correo</dt>
          <dd className="mt-1 text-sm text-gray-900">{record.email}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Teléfono</dt>
          <dd className="mt-1 text-sm text-gray-900">{record.phone}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Usuario creado</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {record.customer?.createdAt && (
              <Moment
                date={record.customer.createdAt}
                format="DD-MM-YYYY hh:mm:ss"
              />
            )}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Ocupación</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {record.customer.occupationName}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Nacionalidad</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {record.customer.nationality_desc}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            País de nacimiento
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {record.customer.birthPlace}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Evaluacion Solicitud
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {getEvaluationStatus(record.customer?.creditAnalysis || [])}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Actualización</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {record.customer?.updatedAt && (
              <Moment
                date={record.customer.updatedAt}
                format="DD-MM-YYYY hh:mm:ss"
              />
            )}
          </dd>
        </div>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 p-8 bg-gray-50">
        <EditableField
          onSubmit={onSubmitCurp}
          label="CURP"
          value={record.customer?.curp}
        />
        <EditableField
          onSubmit={onSubmitRfc}
          label="RFC"
          value={record.customer?.rfc}
        />
      </dl>
    </>
  );
};
