import {injectable} from 'inversify';
import {CreditListResponse} from '../models';
import {
  AuthorizeCreditInterface,
  CollectionRepositoryInterface,
} from './baseRepository.interface';
import axios from 'axios';
import {API_URL} from '../../settings';
import {toCamelCase} from '../../utils';
import {GenericResponse} from '../models/baseResponse';

@injectable()
class CreditRepository
  implements
    CollectionRepositoryInterface<CreditListResponse>,
    AuthorizeCreditInterface<GenericResponse>
{
  async getAll(query: {
    page: number;
    pageSize: number;
    filters: object;
  }): Promise<CreditListResponse> {
    try {
      const response = await axios.get(API_URL + 'credit_requests', {
        params: {
          page: query.page,
          ...query.filters,
        },
      });
      return toCamelCase(response.data) as CreditListResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async authorizeCreditOrigination({
    customer_id,
    action,
  }: {
    customer_id: string;
    action: string;
  }): Promise<GenericResponse> {
    try {
      const response = await axios.post(
        API_URL + 'credit-origination/authorize',
        {
          customer_id,
          action,
        }
      );
      return toCamelCase(response.data) as GenericResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
export default CreditRepository;
