import React from 'react';
import {Link, Flex} from '@chakra-ui/react';
import {NavItemProps} from './interfaces';
import {NavLink as ReachLink} from 'react-router-dom';

const NavItem = ({
  children,
  to,
  handlerClick,
  sidebarFullIsOpen,
}: NavItemProps) => (
  <Link
    as={ReachLink}
    to={to}
    color="white"
    style={{textDecoration: 'none'}}
    _activeLink={{fontWeight: 'bold'}}
    paddingY="5"
    display="block"
    onClick={handlerClick}
  >
    <Flex
      alignItems="center"
      justifyContent={sidebarFullIsOpen ? ' flex-start' : 'center'}
    >
      {children}
    </Flex>
  </Link>
);
export default NavItem;
