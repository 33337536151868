import {LinkItemProps} from './interfaces';
import {
  BsClipboard,
  BsCreditCard,
  BsPeople,
  BsPeopleFill,
  BsCurrencyDollar,
} from 'react-icons/bs';

const LinkItems: Array<LinkItemProps> = [
  {
    name: 'users',
    page: '/users',
    text: 'Usuarios',
    icon: BsPeople,
  },
  {
    name: 'credit_applications',
    page: '/credit_applications',
    text: 'Solicitudes crédito',
    icon: BsCreditCard,
  },
  {
    name: 'onboardings',
    page: '/onboardings',
    text: 'OB/Expendiente',
    icon: BsClipboard,
  },
  {
    name: 'admin_users',
    page: '/admin_users',
    text: 'Usuarios Admin',
    icon: BsPeopleFill,
  },
  {
    name: 'cashback',
    page: '/cashback',
    text: 'Cashback',
    icon: BsCurrencyDollar,
  },
];
export {LinkItems};
