import React, {useState} from 'react';
import {Heading, Divider} from '@chakra-ui/react';

import {UserDetailProps} from '../Users/interfaces';
import {setImages} from './utils';

export const Address = ({user}: UserDetailProps) => {
  const [address] = useState(user.customer.address);
  return (
    address && (
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
        <Heading as="h5" size="sm" className="sm:col-span-6">
          Dirección
        </Heading>
        <Divider className="sm:col-span-6" />
        <div className="sm:col-span-4">
          <dt className="text-sm font-medium text-gray-500">Calle</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.street}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500"># Exterior</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.extNumber}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500"># interior</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.intNumber}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Colonia</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.neighborhood}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Estado</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.state}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">CP</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.postalCode}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Tipo</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.type}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">País</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.country}</dd>
        </div>
        {address.images && (
          <div className="sm:col-span-6">
            <dt className="text-sm font-medium text-gray-500">
              Comprobante de domicilio
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <div className="mt-1 space-x-4 text-gray-900 inline-flex">
                {setImages(address?.images || [], 'PROOF_OF_ADDRESS', true)}
              </div>
            </dd>
          </div>
        )}
      </dl>
    )
  );
};
