import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Spinner,
  Center,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Input,
  Icon,
  IconButton,
  Skeleton,
  Heading,
  Thead,
  Th,
  Text,
  useDisclosure,
  useToast,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import {BsCurrencyDollar} from 'react-icons/bs';
import React, {useEffect, useState} from 'react';
import {container} from '../../container';
import {
  CashBackRepositoryInterface,
  CashBackUpdateInterface,
  PaymentHistoryInterface,
} from '../../common/repositories/baseRepository.interface';
import {Types} from '../../types';
import Cashback, {PaymentHistory, Payments} from '../../models/cashback';
import {CashbackListResponse} from '../../common/models/userListResponse';
import {Account} from '../../models';
import {BaseActionsResponse} from '../../common/models/baseResponse';
import ConfirmModal from '../../common/components/EditableField/ConfirmModal';
import {
  FaWindowClose,
  FaCheckSquare,
  FaPlusSquare,
  FaMinusSquare,
} from 'react-icons/fa';
import {User} from '../../models';

export interface CashBackDetailProps {
  user: User;
  onCloseGlobal: () => void;
}

export const CashBackDetail = ({user, onCloseGlobal}: CashBackDetailProps) => {
  const pageSize = 20;
  const [page] = React.useState(1);
  const [filters] = React.useState({});
  const [history, setHistory] = useState<Array<Cashback>>([]);
  const [historyPayments, setHistoryPayments] = useState<Array<Payments>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPayments, setIsLoadingPayments] = React.useState(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    undefined
  );
  const [availableCashback, setAvailableCashback] = useState<
    Number | undefined
  >(undefined);
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [action, setAction] = useState<string | undefined>(undefined);
  const [error, setError] = useState({
    DETAIL: false,
    PAYMENTS: false,
  });
  const toast = useToast();

  const cashbackService = container.get<
    CashBackRepositoryInterface<CashbackListResponse> &
      PaymentHistoryInterface<PaymentHistory> &
      CashBackUpdateInterface<BaseActionsResponse>
  >(Types.CASHBACK_REPOSITORY);

  const getTextDate = (dateString: string) => {
    const [day, month, year] = dateString.split('-');
    const value = new Date(Number(year), Number(month) - 1, Number(day));

    const text = value.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return text;
  };

  const getDetails = () => {
    if (user.customer.account) {
      setAccount(user.customer.account);
      const account_id = user.customer.account.id;
      cashbackService
        .cashbackHistoryDetail({
          page,
          pageSize,
          filters,
          account_id,
        })
        .then(result => {
          setIsLoading(false);
          if (result) {
            setHistory(result.items);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError(current => ({
            ...current,
            DETAIL: true,
          }));
        });
    }
  };

  const getPaymentsHistory = () => {
    if (user.customer.account) {
      setAccount(user.customer.account);
      const account_id = user.customer.account.id;
      cashbackService
        .cashbackPaymentsHistory({
          account_id,
        })
        .then(response => {
          if (response) {
            setAvailableCashback(response.availableCashback);
            setHistoryPayments(response.payments);
            setIsLoadingPayments(true);
          }
        })
        .catch(() => {
          setIsLoadingPayments(true);
          setError(current => ({
            ...current,
            PAYMENTS: true,
          }));
        });
    }
  };

  const updateCashback = (action: string) => {
    setIsEditing(!isEditing);
    setCurrentValue(availableCashback?.toString());
    setAction(action);
  };

  useEffect(() => {
    getDetails();
    getPaymentsHistory();
  }, [user]);

  const confirmSave = () => {
    const value = Number(currentValue);
    cashbackService
      .updateCashBack({
        account_id: account?.id || '',
        amount: value,
        action: action || '',
      })
      .then(response => {
        if (response.message === 'OK') {
          messageResponse('Operación exitosa', true);
        } else {
          messageResponse(response.message, false);
        }
        onCloseGlobal();
      })
      .catch(error => {
        console.log('error', error);
        console.error(error);
        const {response} = error;
        const {message} = response.data;
        messageResponse(
          message ? message : 'Error al actualizar cashback',
          false
        );
      });
    onClose();
  };

  const messageResponse = (title: string, isSuccess: boolean) => {
    return toast({
      title,
      duration: 3000,
      isClosable: true,
      status: isSuccess ? 'success' : 'error',
    });
  };

  const onSave = () => {
    setIsEditing(false);
    onOpen();
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (isLoading) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Spinner />
        </Center>
      </Flex>
    );
  } else {
    return (
      <>
        <Box>
          <Box p="6" display="flex" flexDirection="column">
            <Box>
              Cuenta: <strong>{account?.accountNumber}</strong>
            </Box>

            <Box
              flexDirection="row"
              justifyContent="space-evenly"
              display="flex"
              width="100%"
              alignItems="center"
            >
              <Box
                flex={10}
                flexDirection="row"
                display="flex"
                width="100%"
                alignItems="center"
                id="data1"
              >
                <Box
                  flex={10}
                  flexDirection="row"
                  display="flex"
                  width="100%"
                  id="data2"
                  alignItems="center"
                >
                  <Box flex={2}>
                    <Skeleton width="10em" isLoaded={isLoadingPayments}>
                      {availableCashback && (
                        <Text as="b" fontSize="md">
                          ${availableCashback.toString()}
                        </Text>
                      )}
                    </Skeleton>
                    {error.PAYMENTS && (
                      <Text as="b" fontSize="md">
                        Error
                      </Text>
                    )}
                  </Box>
                  <Box
                    flex={7}
                    flexDirection="row"
                    display="flex"
                    id="data3"
                    alignItems="center"
                  >
                    <Box flex={1}></Box>
                  </Box>
                </Box>
              </Box>
              {!error.PAYMENTS && (
                <>
                  <Box flex={1}>
                    <IconButton
                      variant="outline"
                      icon={<FaPlusSquare style={{color: '#2E9E23'}} />}
                      aria-label="sumar"
                      onClick={() => updateCashback('increase')}
                    />
                  </Box>
                  <Box flex={1}>
                    <IconButton
                      variant="outline"
                      icon={<FaMinusSquare style={{color: '#C1111C'}} />}
                      aria-label="restar"
                      onClick={() => updateCashback('decrease')}
                    />
                  </Box>
                </>
              )}
              <Box flex={6}></Box>
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">cashback acumulado</Text>
            </Box>
            <Spacer />
            <Box
              flex={4}
              id="edit-input"
              flexDirection="row"
              display="flex"
              alignItems="center"
              width="50%"
            >
              {isEditing && (
                <>
                  <Box flex={1}>
                    <Text fontSize="xs" style={{textAlign: 'center'}}>
                      {action === 'increase' ? 'sumar' : 'restar'}
                    </Text>
                  </Box>
                  <Box flex={2} style={{paddingRight: '1em'}}>
                    <Tooltip
                      label="El monto ingresado aquí se sumará/restará al monto de cashback global"
                      bg="yellow.500"
                      placement="bottom"
                      hasArrow
                    >
                      <Input
                        className={isEditing ? 'inline' : 'hidden'}
                        value={currentValue || ''}
                        type="number"
                        variant="filled"
                        size="sm"
                        focusBorderColor="gray.900"
                        onChange={event =>
                          setCurrentValue(event.currentTarget.value.trim())
                        }
                      />
                    </Tooltip>
                  </Box>
                  <Box flex={1}>
                    <IconButton
                      variant="outline"
                      icon={<FaWindowClose style={{color: '#C1111C'}} />}
                      aria-label="cerrar"
                      onClick={() => setIsEditing(!isEditing)}
                    />
                  </Box>
                  <Box flex={1}>
                    <IconButton
                      variant="outline"
                      icon={<FaCheckSquare style={{color: '#2E9E23'}} />}
                      aria-label="confirmar"
                      onClick={() => onSave()}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <br />
          <Heading as="h4" size="md">
            Cashback Acumulado
          </Heading>
          <br />
          <Accordion allowToggle>
            {history.length > 0 &&
              history.map(item => (
                <AccordionItem key={item.id}>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        <Icon as={BsCurrencyDollar} boxSize={4} />
                        <strong> Corte </strong>
                        {getTextDate(item.statementDate)}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {item.transactions && item.transactions.length > 0 && (
                      <Table variant="simple" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Descripción</Th>
                            <Th style={{textAlign: 'right'}}>Monto</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {item.transactions.map(transaction => (
                            <Tr
                              key={`${
                                transaction.referenceNumber
                              }-${Math.random()}`}
                            >
                              <Td>{transaction.description}</Td>
                              <Td style={{textAlign: 'right'}}>
                                {formatter.format(
                                  transaction.transactionAmount
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
          {history.length === 0 && !error.DETAIL && (
            <Table variant="simple" size="sm">
              <Tbody>
                <Tr>
                  <Td>
                    No existen compras disponibles para pagar con Cashback
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          )}
          {error.DETAIL && (
            <Table variant="simple" size="sm">
              <Tbody>
                <Tr>
                  <Td>Error</Td>
                </Tr>
              </Tbody>
            </Table>
          )}
          <br />
          <Heading as="h4" size="md">
            Cashback utilizado
          </Heading>
          <br />
          {historyPayments && historyPayments.length > 0 && (
            <Accordion allowToggle>
              <AccordionItem key={Math.random()}>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Detalles
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th>Descripción</Th>
                        <Th>Fecha</Th>
                        <Th style={{textAlign: 'right'}}>Monto</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {historyPayments.map(payment => (
                        <Tr key={`${payment.referenceNumber}-${Math.random()}`}>
                          <Td>{payment.description}</Td>
                          <Td>{payment.postDate}</Td>
                          <Td style={{textAlign: 'right'}}>
                            {formatter.format(payment.amount)}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          {error.PAYMENTS && (
            <Table variant="simple" size="sm">
              <Tbody>
                <Tr>
                  <Td>Error</Td>
                </Tr>
              </Tbody>
            </Table>
          )}
          {historyPayments.length === 0 && !error.PAYMENTS && (
            <Table variant="simple" size="sm">
              <Tbody>
                <Tr>
                  <Td>No existe cashback utilizado</Td>
                </Tr>
              </Tbody>
            </Table>
          )}
        </Box>
        <ConfirmModal
          isOpen={isOpen}
          onClose={onClose}
          confirmSave={confirmSave}
        />
      </>
    );
  }
};
