import {
  CollectionRepositoryInterface,
  UserActionsInterface,
} from './baseRepository.interface';
import {UserListResponse} from '../models';
import axios from 'axios';
import {injectable} from 'inversify';
import {API_URL} from '../../settings';
import {toCamelCase} from '../../utils';
import {BaseActionsResponse} from '../models/baseResponse';

@injectable()
class UserRepository
  implements
    CollectionRepositoryInterface<UserListResponse>,
    UserActionsInterface<BaseActionsResponse>
{
  async getAll(query: {
    page: number;
    pageSize: number;
    filters: object;
  }): Promise<UserListResponse> {
    try {
      const response = await axios.get(API_URL + 'users', {
        params: {
          page: query.page,
          ...query.filters,
        },
      });
      return toCamelCase(response.data) as UserListResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateEmail({
    value,
    userId,
  }: {
    value: string;
    userId: string;
  }): Promise<BaseActionsResponse> {
    try {
      const response = await axios.post(API_URL + 'users/update_email', {
        user_id: userId,
        value,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updatePhone({
    value,
    userId,
  }: {
    value: string;
    userId: string;
  }): Promise<BaseActionsResponse> {
    try {
      const response = await axios.post(API_URL + 'users/update_phone', {
        user_id: userId,
        value,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
export default UserRepository;
