import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {ProtectedRouteProps} from './interfaces';
import authContext from '../../hooks/useAuth';

const ProtectedRoute = ({
  children,
  redirectPath = '/login',
}: ProtectedRouteProps) => {
  const {isAuthenticated} = useContext(authContext);

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
};
export default ProtectedRoute;
