import {TableRowProps} from './interfaces';
import {Button, Flex, Td, Tr} from '@chakra-ui/react';
import {BsReverseLayoutSidebarInsetReverse} from 'react-icons/bs';
import React from 'react';
import {User} from '../../models';
import TableItem from './TableItem';
import {OpacityFull} from '../../common/constants';

const TableRow = ({user, onToggle, setUser}: TableRowProps) => {
  const handleSidebarButtonOpen = (user: User) => {
    onToggle();
    setUser(user);
  };

  return (
    <Tr>
      <Td>
        <Flex justifyContent="space-between">
          {user.customer.customerId}
          {user.customer.account && (
            <Button
              leftIcon={<BsReverseLayoutSidebarInsetReverse />}
              style={OpacityFull}
              onClick={() => handleSidebarButtonOpen(user)}
              colorScheme="gray"
              borderRadius="lg"
              size="xs"
              marginLeft="2"
              variant="outline"
            >
              Ver
            </Button>
          )}
        </Flex>
      </Td>
      <Td>
        <TableItem value={user.customer.name} />
      </Td>
      <Td>
        <TableItem
          value={user.customer.lastName + ' ' + user.customer.otherLastName}
        />
      </Td>
      <Td>
        <TableItem value={user.email} />
      </Td>
      <Td>
        <TableItem value={user.phone} />
      </Td>
      <Td>
        <TableItem value={user.customer.account?.accountNumber || null} />
      </Td>
    </Tr>
  );
};

export default TableRow;
