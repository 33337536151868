const placeHolders = {
  id: 'Folio',
  name: 'Nombre',
  last_name: 'Apellido',
  email: 'Correo',
};

const initialValues = {
  id: '',
  name: '',
  last_name: '',
  email: '',
};

export {placeHolders, initialValues};
