import React, {useContext, useState} from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import NavItem from './NavItem';
import {SidebarContentProps} from './interfaces';
import {LinkItems} from './constants';
import {BsChevronDoubleLeft, BsChevronDoubleRight} from 'react-icons/bs';
import {ALLOWED_PAGES, Roles} from '../../../permissions';
import authContext from '../../hooks/useAuth';

const OpacityZero = {opacity: 0};
const OpacityFull = {opacity: 1};

const SidebarContent = ({
  onClose,
  sidebarFullToggle,
  sidebarFullIsOpen = true,
  ...boxProps
}: SidebarContentProps) => {
  const {isAuthenticated, roles} = useContext(authContext);
  const role = roles[0] || '';

  const handlerNavItemClick = () => {
    onClose ? onClose() : null;
  };
  const [buttonStyle, setButtonStyle] = useState(
    sidebarFullIsOpen ? OpacityZero : OpacityFull
  );
  const onMouseEnter = () => {
    if (sidebarFullIsOpen) {
      setButtonStyle(OpacityFull);
    }
  };
  const onMouseLeave = () => {
    if (sidebarFullIsOpen) {
      setButtonStyle(OpacityZero);
    }
  };
  const imageWidth = sidebarFullIsOpen ? 24 : 0;
  const sideBarIcon = sidebarFullIsOpen
    ? BsChevronDoubleLeft
    : BsChevronDoubleRight;

  const buildNavItems = () => {
    const allowed_pages = ALLOWED_PAGES[role as Roles] || [];
    const navItems: JSX.Element[] = [];
    LinkItems.map((link, key) => {
      if (allowed_pages.includes(link.name)) {
        navItems.push(
          <NavItem
            key={key}
            to={link.page}
            handlerClick={handlerNavItemClick}
            sidebarFullIsOpen={sidebarFullIsOpen}
          >
            <Icon as={link.icon} />
            {sidebarFullIsOpen && <Text marginLeft="4">{link.text}</Text>}
          </NavItem>
        );
      }
    });
    return navItems;
  };
  return (
    <Box bg="brand.sidebarBg" position="fixed" height="full" {...boxProps}>
      <Flex
        paddingX={{base: 4, md: 5}}
        paddingY="4"
        justifyContent={sidebarFullIsOpen ? ' space-between' : 'center'}
      >
        <Image width={imageWidth} src="/f_credit_logo.svg" alt="FCredit" />
        {onClose && <CloseButton color="white" onClick={onClose} />}
        {sidebarFullToggle && (
          <IconButton
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            aria-label="toggle_sidebar"
            variant="ghost"
            colorScheme="whiteAlpha"
            onClick={sidebarFullToggle}
            style={buttonStyle}
            icon={<Icon color="white" as={sideBarIcon} />}
          />
        )}
      </Flex>
      <Box paddingX={{base: 4, md: 5}}>
        {isAuthenticated && buildNavItems()}
      </Box>
    </Box>
  );
};

export default SidebarContent;
