import React from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {CollectionRepositoryInterface} from '../../common/repositories/baseRepository.interface';
import {container} from '../../container';
import {Types} from '../../types';
import {Credit} from '../../models';
import Filters from './Filters';
import Table from './Table';
import {Pagination} from './../../common/components';
import {FilterValues} from './interfaces';
import {CreditListResponse} from '../../common/models';
import CreditApplicationDetail from './CreditApplicationDetail';

const CreditApplications = () => {
  const pageSize = 20;
  const [page, setCurrentPage] = React.useState(1);
  const [filters, setFilters] = React.useState({});
  const [credit, setCredit] = React.useState<Credit>();
  const {isOpen, onClose, onToggle} = useDisclosure();

  const creditService = container.get<
    CollectionRepositoryInterface<CreditListResponse>
  >(Types.CREDIT_REPOSITORY);

  const {isLoading, isFetching, error, data} = useQuery(
    ['credits', page, filters],
    () => creditService.getAll({page, filters, pageSize}),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const onFiltersSubmit = (values: FilterValues) => {
    setFilters(values);
  };
  const credits: Array<Credit> = data?.items || [];
  const totalCount = data?.total || 0;
  const hasPrevPage = page > 1;
  const hasNextPage = data?.next !== null;
  if (isLoading || isFetching) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Spinner />
        </Center>
      </Flex>
    );
  } else if (error) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Heading fontWeight="light" fontSize="3xl">
            Error
          </Heading>
        </Center>
      </Flex>
    );
  } else {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Filters onSubmit={onFiltersSubmit} />
        <Table credits={credits} onToggle={onToggle} setCredit={setCredit} />
        <Pagination
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={page}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          setCurrentPage={setCurrentPage}
        />
        {credit && (
          <Drawer onClose={onClose} isOpen={isOpen} size="xl">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <Heading fontWeight="light" fontSize="3xl">
                  Solicitud de crédito
                </Heading>
              </DrawerHeader>
              <DrawerBody>
                <CreditApplicationDetail credit={credit} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </Flex>
    );
  }
};

export default CreditApplications;
