import React from 'react';
import {useFormik} from 'formik';

import {Button, Input, Box, Stack} from '@chakra-ui/react';
import {FiltersProps} from './interfaces';
import {placeHolders, initialValues} from './constants';

const Filters = ({onSubmit}: FiltersProps) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={{base: 'column', md: 'row'}} width="full">
        <Box flexGrow="1">
          <Input
            type="email"
            name="email"
            id="email"
            placeholder={placeHolders.email}
            focusBorderColor="gray.900"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </Box>
        <Box flexGrow="1">
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder={placeHolders.phone}
            focusBorderColor="gray.900"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </Box>
        <Box flexGrow="1">
          <Input
            name="curp"
            id="curp"
            type="text"
            placeholder={placeHolders.curp}
            focusBorderColor="gray.900"
            onChange={formik.handleChange}
            value={formik.values.curp}
          />
        </Box>
        <Box flexGrow="1">
          <Input
            type="date"
            name="createdAt"
            id="createdAt"
            placeholder={placeHolders.createdAt}
            focusBorderColor="gray.900"
            onChange={formik.handleChange}
            value={formik.values.createdAt}
          />
        </Box>
        <Box flexGrow="1">
          <Input
            type="date"
            name="updatedAt"
            id="updatedAt"
            placeholder={placeHolders.updatedAt}
            focusBorderColor="gray.900"
            onChange={formik.handleChange}
            value={formik.values.updatedAt}
          />
        </Box>
        <Box flexGrow="1">
          <Button
            width="full"
            isLoading={formik.isSubmitting}
            type="submit"
            bg="gray.900"
            color="white"
            colorScheme="blackAlpha"
          >
            Filtrar
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
export default Filters;
