import React from 'react';
import {TableProps} from './interfaces';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';

const AdminUsersTable = ({adminUsers}: TableProps) => {
  return (
    <Box>
      <TableContainer paddingY="4">
        <Table backgroundColor="white">
          <Thead>
            <Tr>
              <Th>Sub</Th>
              <Th>Email</Th>
              <Th>Status</Th>
              <Th>Creación</Th>
              <Th>Activo</Th>
              <Th>Grupos</Th>
            </Tr>
          </Thead>
          <Tbody>
            {adminUsers.map(user => (
              <TableRow key={user.sub} adminUser={user} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default AdminUsersTable;
