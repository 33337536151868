import React from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {ConfirmModalProps} from './interfaces';

const ConfirmModal = ({isOpen, onClose, confirmSave}: ConfirmModalProps) => (
  <Modal
    closeOnOverlayClick={false}
    blockScrollOnMount={false}
    isOpen={isOpen}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirmar cambio</ModalHeader>
      <ModalCloseButton />
      <ModalFooter>
        <Button
          colorScheme="blackAlpha"
          bg="gray.900"
          color="white"
          mr={3}
          onClick={confirmSave}
        >
          Guardar
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
export default ConfirmModal;
