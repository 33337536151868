const Types = {
  USER_REPOSITORY: Symbol('UserRepository'),
  CREDIT_REPOSITORY: Symbol('CreditRepository'),
  ONBOARDING_REPOSITORY: Symbol('OnboardingRepository'),
  ADMIN_USER_REPOSITORY: Symbol('AdminUserRepository'),
  GROUP_REPOSITORY: Symbol('GroupRepository'),
  CASHBACK_REPOSITORY: Symbol('CashBackRepository'),
};

export {Types};
