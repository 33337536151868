import React, {createContext, ReactNode, useEffect, useState} from 'react';
import Auth, {
  CognitoHostedUIIdentityProvider,
  // eslint-disable-next-line node/no-extraneous-import
} from '@aws-amplify/auth';
import {UseAuthProps, CognitoUserExt} from '../interfaces/useAuth';
import axios from 'axios';
import {ChallengeNamesPaths} from '../constants';

const authContext = createContext({} as UseAuthProps);

export const AuthProvider = ({children}: {children: ReactNode}) => {
  const [user, setUser] = useState<CognitoUserExt | null>(null);
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [challengePath, setChallengePath] = useState<string | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user);
        setIsAuthenticated(true);
        if (!user.challengeName) {
          setRoles(
            user.signInUserSession.idToken.payload['cognito:groups'] || []
          );
          axios.defaults.headers.common['Authorization'] =
            user.signInUserSession.idToken.jwtToken;
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const socialSignIn = () =>
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});

  const signIn = async (username: string, password: string) => {
    try {
      setIsLoading(true);
      const user = await Auth.signIn(username, password);
      verifyChallenge(user.challengeName);
      if (!user.challengeName) {
        axios.defaults.headers.common['Authorization'] =
          user.signInUserSession.idToken.jwtToken;
        setRoles(
          user.signInUserSession.idToken.payload['cognito:groups'] || []
        );
      }
      setUser(user);
      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      throw err;
    }
  };

  const completePassword = async (newPassword: string) => {
    try {
      const updated_user = await Auth.completeNewPassword(user, newPassword);
      axios.defaults.headers.common['Authorization'] =
        updated_user.signInUserSession.idToken.jwtToken;
      setRoles(
        updated_user.signInUserSession.idToken.payload['cognito:groups'] || []
      );
      setUser({
        ...updated_user,
        attributes: {
          ...updated_user.challengeParam.userAttributes,
        },
      });
      setChallengePath(null);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const verifyChallenge = (code: string) => {
    try {
      setIsLoading(true);
      const challengePath = ChallengeNamesPaths.get(code) || null;
      setChallengePath(challengePath);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      throw err;
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    setUser(null);
    setIsAuthenticated(false);
    setIsLoading(false);
  };

  const forgotPassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return (
    <authContext.Provider
      value={{
        user,
        roles,
        isAuthenticated,
        isLoading,
        challengePath,
        socialSignIn,
        signIn,
        signOut,
        completePassword,
        forgotPassword,
        forgotPasswordSubmit,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default authContext;
