import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {useContext, useEffect} from 'react';
import {useFormik} from 'formik';
import authContext from '../../common/hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {
  emailRegex,
  invalidEmailError,
  requiredError,
} from '../../common/constants';

const ForgotPassword = () => {
  const {forgotPassword} = useContext(authContext);
  const [sent, setSent] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sent) {
      navigate('/login');
    }
  }, [sent]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async ({email}) => {
      try {
        await forgotPassword(email);
        setSent(true);
      } catch (error) {
        console.log(error);
      }
    },
    validate: values => {
      return {
        ...(!values.email && {email: requiredError}),
        ...(values.email &&
          !emailRegex.test(values.email) && {email: invalidEmailError}),
      };
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Restablecer contraseña</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl id="email">
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  id="email"
                />
                {!!formik.errors.email && formik.touched.email ? (
                  <div style={{color: 'red', fontSize: 'small'}}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </FormControl>
              <Stack>
                <Stack
                  direction={{base: 'column', sm: 'row'}}
                  align={'start'}
                  justify={'space-between'}
                ></Stack>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  width="full"
                  type="submit"
                  bg="gray.900"
                  color="white"
                  colorScheme="blackAlpha"
                >
                  Enviar
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ForgotPassword;
