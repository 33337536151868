import {FaRegEdit, FaWindowClose} from 'react-icons/fa';
import React, {KeyboardEvent, useEffect, useRef, useState} from 'react';
import {EVENT_TYPES} from './constants';
import {Grid, GridItem, Input, useDisclosure} from '@chakra-ui/react';
import {EditableFieldProps} from './interfaces';
import ConfirmModal from './ConfirmModal';

const EditableField = ({onSubmit, value, label}: EditableFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [eventType, setEventType] = useState<string>('');
  const {isOpen, onClose, onOpen} = useDisclosure();

  useEffect(() => {
    if (eventType === EVENT_TYPES.EDIT) {
      inputRef.current?.focus();
    } else if (eventType === EVENT_TYPES.CANCEL) {
      setCurrentValue(value);
    } else if (eventType === EVENT_TYPES.SUBMIT) {
      onOpen();
    }
  }, [eventType]);

  const editButtonHandler = () => {
    if (isEditing) {
      setEventType(EVENT_TYPES.CANCEL);
    } else {
      setEventType(EVENT_TYPES.EDIT);
    }
    setIsEditing(!isEditing);
  };
  const confirmSave = () => {
    onSubmit(currentValue);
    onClose();
  };
  const onSave = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    setIsEditing(false);
    setEventType(EVENT_TYPES.SUBMIT);
  };

  return (
    <>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <Grid templateColumns="repeat(5, 1fr)">
            <GridItem colSpan={3}>
              <label className={isEditing ? 'hidden' : 'inline'}>
                {currentValue}
              </label>
              <Input
                className={isEditing ? 'inline' : 'hidden'}
                ref={inputRef}
                value={currentValue || ''}
                type="text"
                onKeyDown={onSave}
                variant="filled"
                size="sm"
                focusBorderColor="gray.900"
                onChange={event =>
                  setCurrentValue(event.currentTarget.value.trim())
                }
              />
            </GridItem>
            <GridItem className="ml-4 flex-shrink-0">
              <button
                type="button"
                className="rounded-md bg-white font-medium text-black-600 hover:text-black-500 focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2"
                onClick={editButtonHandler}
              >
                {isEditing ? (
                  <FaWindowClose className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <FaRegEdit className="h-5 w-5" aria-hidden="true" />
                )}
              </button>
            </GridItem>
          </Grid>
        </dd>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        confirmSave={confirmSave}
      />
    </>
  );
};

export default EditableField;
