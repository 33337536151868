import React, {useState} from 'react';

import {Button, ButtonGroup, Link, useToast} from '@chakra-ui/react';
import {container} from '../../container';
import {
  AuthorizeCreditInterface,
  CreateRepositoryInterface,
  OnboardingActionsInterface,
} from '../../common/repositories/baseRepository.interface';
import {Types} from '../../types';
import {
  BaseActionsResponse,
  GenericResponse,
} from '../../common/models/baseResponse';

import {UpdateIdLinkRequest, UpdateIdLinkResponse} from './models';
import {User as UserObject} from '../../models';
import LinkGenerator from './LinkGenerator';
import {FaCheckSquare, FaMinusCircle} from 'react-icons/fa';

import {User} from './User';
import {Address} from './Address';
import {Identity} from './Identity';

export type OnboardingDetailsProps = {
  onClose: () => void;
  record: UserObject;
};

const OnboardingDetail = ({record, onClose}: OnboardingDetailsProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState({
    APPROVED: false,
    REJECTED: false,
  });
  const [disabled, setDisabled] = useState(false);
  const onBoardingService = container.get<
    OnboardingActionsInterface<BaseActionsResponse> &
      CreateRepositoryInterface<UpdateIdLinkRequest, UpdateIdLinkResponse>
  >(Types.ONBOARDING_REPOSITORY);

  const authorizeCreditService = container.get<
    AuthorizeCreditInterface<GenericResponse>
  >(Types.CREDIT_REPOSITORY);

  const authorizeCreditOrigination = (action: string) => {
    const isApproved = action === 'APPROVED';
    setIsLoading({APPROVED: isApproved, REJECTED: !isApproved});
    authorizeCreditService
      .authorizeCreditOrigination({
        customer_id: record.customer.id,
        action,
      })
      .then(() => {
        setIsLoading({APPROVED: false, REJECTED: false});
        setDisabled(true);
        toast({
          title: 'Operación exitosa',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
        onClose();
      })
      .catch(error => {
        console.error(error);
        setIsLoading({APPROVED: false, REJECTED: false});
        toast({
          title: 'Ocurrió un error',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      });
  };

  return (
    <div className="grid py-5 gap-y-7">
      <dl className="px-5">
        <ButtonGroup>
          <Button
            disabled={!record.customer.contract?.contractPath}
            as={Link}
            href={record.customer.contract?.contractPath}
            isExternal
          >
            Ver contrato
          </Button>
          <Button
            disabled={!record.customer.contract?.contractSignedPath}
            as={Link}
            href={record.customer.contract?.contractSignedPath}
            isExternal
          >
            Ver contrato firmado
          </Button>
          <LinkGenerator
            title="Generar link INE"
            requestData={{userId: record.id, isPassport: false}}
            generateLinkFunction={onBoardingService.create}
          />
          <LinkGenerator
            title="Generar link Pasaporte"
            requestData={{userId: record.id, isPassport: true}}
            generateLinkFunction={onBoardingService.create}
          />
        </ButtonGroup>
      </dl>
      {record.customer.onboardingStatus === 'CREDIT_REPORT_PROCESSING' && (
        <dl className="px-5">
          <ButtonGroup>
            <Button
              leftIcon={
                <FaCheckSquare style={{color: '#2E9E23'}} size="1.5rem" />
              }
              disabled={disabled}
              isLoading={isLoading.APPROVED}
              loadingText="Procesando"
              onClick={() => authorizeCreditOrigination('APPROVED')}
            >
              Aprobar
            </Button>
            <Button
              leftIcon={
                <FaMinusCircle style={{color: '#C1111C'}} size="1.5rem" />
              }
              disabled={disabled}
              isLoading={isLoading.REJECTED}
              loadingText="Procesando"
              onClick={() => authorizeCreditOrigination('REJECTED')}
            >
              Rechazar
            </Button>
          </ButtonGroup>
        </dl>
      )}
      <User record={record} />
      <Address user={record} />
      <Identity record={record} />
    </div>
  );
};

export default OnboardingDetail;
