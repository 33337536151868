import React from 'react';
import {TableProps} from './interfaces';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';

const RecordsTable = ({records, onToggle, setRecord}: TableProps) => {
  return (
    <Box>
      <TableContainer paddingY="4">
        <Table backgroundColor="white">
          <Thead>
            <Tr>
              <Th>Correo</Th>
              <Th>Usuario creación</Th>
              <Th>CURP</Th>
              <Th>Teléfono</Th>
              <Th>Clave secreta</Th>
              <Th>Correo verificado</Th>
              <Th>INE validada</Th>
              <Th>CURP validada</Th>
              <Th>HIT Listas Negras</Th>
              <Th>Teléfono validado</Th>
              <Th>Evaluación</Th>
              <Th>Dirección Validada</Th>
              <Th>Contrato Firmado</Th>
              <Th>Cuenta Fiserv</Th>
              <Th>Actualización</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.map(user => (
              <TableRow
                key={user.id}
                record={user}
                onToggle={onToggle}
                setRecord={setRecord}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default RecordsTable;
