export enum Roles {
  ADMIN = 'Administrator',
  BACK_OFFICE = 'BackOffice',
  CUSTOMER_SERVICE = 'CustomerService',
}

type PermissionOptions = {[key in Roles]: string[]};

export const ALLOWED_PAGES: PermissionOptions = {
  [Roles.ADMIN]: [
    'users',
    'credit_applications',
    'onboardings',
    'admin_users',
    'cashback',
  ],
  [Roles.BACK_OFFICE]: ['users', 'credit_applications', 'onboardings'],
  [Roles.CUSTOMER_SERVICE]: ['users', 'onboardings'],
};
