import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import {LinkGeneratorProps} from './interfaces';
import {useMutation} from '@tanstack/react-query';
import {UpdateIdLinkRequest} from './models';

const LinkGenerator = ({
  title,
  requestData,
  generateLinkFunction,
}: LinkGeneratorProps) => {
  const {isOpen, onToggle, onClose} = useDisclosure();
  const [link, setLink] = React.useState('');
  const toast = useToast();
  const {onCopy} = useClipboard(link);

  const {mutateAsync, isError, isLoading} = useMutation({
    mutationFn: (data: UpdateIdLinkRequest) => generateLinkFunction(data),
  });

  const onButtonClick = async () => {
    const res = await mutateAsync(requestData);
    if (isError) {
      toast({
        title: 'Error al generar link',
        duration: 3000,
        isClosable: true,
        status: 'error',
      });
      return;
    }
    setLink(res.url);
    onToggle();
  };

  const copyLink = () => {
    onCopy();
    toast({
      title: 'Link copiado',
      duration: 3000,
      isClosable: true,
      status: 'success',
    });
  };

  return (
    <>
      <Popover isLazy isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button onClick={onButtonClick}>
            {isLoading ? <Spinner /> : title}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <div className="p-2">
              <h4 className="text-sm font-semibold text-gray-800">
                Compartir link
              </h4>
              <div className="p-1 my-1 bg-gray-100 rounded-md flex justify-between items-center">
                <span className="p-1 truncate w-3/5">{link}</span>
                <Button
                  size="xs"
                  bg="gray.900"
                  color="white"
                  colorScheme="blackAlpha"
                  onClick={copyLink}
                >
                  Copiar Link
                </Button>
              </div>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default LinkGenerator;
