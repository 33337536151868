import React from 'react';
import {TableProps} from './interfaces';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';

const CreditApplicationsTable = ({
  credits,
  onToggle,
  setCredit,
}: TableProps) => {
  return (
    <Box>
      <TableContainer paddingY="4">
        <Table backgroundColor="white">
          <Thead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Apellido</Th>
              <Th>Correo</Th>
              <Th>Estatus</Th>
              <Th>Fecha y hora</Th>
              <Th>Folio</Th>
            </Tr>
          </Thead>
          <Tbody>
            {credits.map(credit => (
              <TableRow
                key={credit.id}
                credit={credit}
                onToggle={onToggle}
                setCredit={setCredit}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CreditApplicationsTable;
