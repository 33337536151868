import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React, {useContext} from 'react';
import {SidebarWithHeader} from './common/components';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {
  AdminUsers,
  CompletePassword,
  CreditApplications,
  ForgotPassword,
  Login,
  NewPassword,
  Onboardings,
  Users,
  CashBack,
} from './pages';
import ProtectedRoute from './common/components/ProtectedRoute';
import authContext from './common/hooks/useAuth';

const queryClient = new QueryClient();

const App = () => {
  const {isLoading} = useContext(authContext);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/create-password"
            element={
              <ProtectedRoute>
                <CompletePassword />
              </ProtectedRoute>
            }
          />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />(
          {!isLoading && (
            <Route element={<SidebarWithHeader />}>
              <Route
                path="/users"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/onboardings"
                element={
                  <ProtectedRoute>
                    <Onboardings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/credit_applications"
                element={
                  <ProtectedRoute>
                    <CreditApplications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin_users"
                element={
                  <ProtectedRoute>
                    <AdminUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cashback"
                element={
                  <ProtectedRoute>
                    <CashBack />
                  </ProtectedRoute>
                }
              />
            </Route>
          )}
          )
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
export default App;
