import {
  CashBackRepositoryInterface,
  CashBackUpdateInterface,
  PaymentHistoryInterface,
} from './baseRepository.interface';
import axios from 'axios';
import {injectable} from 'inversify';
import {API_URL} from '../../settings';
import {toCamelCase} from '../../utils';
import {CashbackListResponse} from '../models/userListResponse';
import {PaymentHistory} from '../../models/cashback';
import {BaseActionsResponse} from '../models/baseResponse';

@injectable()
class CashBackRepository
  implements
    CashBackRepositoryInterface<CashbackListResponse>,
    PaymentHistoryInterface<PaymentHistory>,
    CashBackUpdateInterface<BaseActionsResponse>
{
  async updateCashBack({
    account_id,
    amount,
    action,
  }: {
    account_id: string;
    amount: number;
    action: string;
  }): Promise<BaseActionsResponse> {
    try {
      const response = await axios.post(
        API_URL + 'cashback/update-available-cashback',
        {
          account_id,
          amount,
          action,
        }
      );
      return toCamelCase(response.data) as BaseActionsResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async cashbackPaymentsHistory(query: {
    account_id: string;
  }): Promise<PaymentHistory> {
    try {
      const response = await axios.get(
        API_URL + 'cashback/cashback-payments-history',
        {
          params: {
            account_id: query.account_id,
          },
        }
      );
      return toCamelCase(response.data) as PaymentHistory;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async cashbackHistoryDetail(query: {
    page: number;
    pageSize: number;
    filters: object;
    account_id: string;
  }): Promise<CashbackListResponse> {
    try {
      const response = await axios.get(API_URL + 'cashback/cashback-history', {
        params: {
          account_id: query.account_id,
        },
      });
      return toCamelCase(response.data) as CashbackListResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default CashBackRepository;
