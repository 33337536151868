import {
  Avatar,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {FiMenu} from 'react-icons/fi';
import React, {useContext} from 'react';

import {MobileNavProps} from './interfaces';
import authContext from '../../hooks/useAuth';
import {useNavigate} from 'react-router-dom';

const MobileNav = ({onOpen, title, ...flexProps}: MobileNavProps) => {
  const {signOut, user} = useContext(authContext);
  const navigate = useNavigate();

  const onLogout = () => {
    signOut();
    navigate('/login');
  };

  return (
    <Flex {...flexProps}>
      <IconButton
        display={{base: 'flex', md: 'none'}}
        onClick={onOpen}
        variant="outline"
        aria-label="open_menu"
        icon={<FiMenu />}
      />
      <Heading fontWeight="light" fontSize="3xl">
        {title}
      </Heading>
      <HStack spacing={{base: 0, md: 6}}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{boxShadow: 'none'}}
            >
              <HStack>
                <VStack
                  display={{base: 'none', md: 'flex'}}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user && user.attributes.email}</Text>
                </VStack>
                <Avatar size={'sm'} src="/avatar.png" />
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={onLogout}>Salir</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
export default MobileNav;
