import {camelCase, isArray, isObject, snakeCase, transform} from 'lodash';

export const toCamelCase = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? toCamelCase(value as Record<string, unknown>)
        : value;
    }
  );

export const toSnakeCase = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const snakeKey = isArray(target) ? key : snakeCase(key);
      result[snakeKey] = isObject(value)
        ? toSnakeCase(value as Record<string, unknown>)
        : value;
    }
  );
