import {AiOutlineLine} from 'react-icons/ai';
import {IoCheckbox} from 'react-icons/io5';
import {FaMinusCircle} from 'react-icons/fa';
import {GrClose} from 'react-icons/gr';
import React from 'react';
import {Image} from '@chakra-ui/react';
import {IdentificationImages} from '../../models';

export const IconStatus = (status: boolean | null) => {
  switch (status) {
    case true:
      return <IoCheckbox style={{color: '#2E9E23'}} size="1.5rem" />;
    case false:
      return <FaMinusCircle style={{color: '#C1111C'}} size="1.5rem" />;
    case null:
      return <AiOutlineLine size="1.5rem" />;
    default:
      return <GrClose style={{color: '#C1111C'}} size="1.5rem" />;
  }
};

export const setImages = (
  images: Array<IdentificationImages>,
  type: string,
  ignoredType: boolean
) => {
  return images?.map((image, index) => {
    if (image.identificationImageType === type || ignoredType) {
      return (
        <Image
          key={index}
          src={image.path}
          alt={type}
          boxSize={'260px'}
          objectFit={'cover'}
        />
      );
    } else {
      return null;
    }
  });
};
