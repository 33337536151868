import axios from 'axios';
import {injectable} from 'inversify';
import {API_URL} from '../../settings';
import {AdminGroupCreateRequest} from '../models';
import {AdminGroup} from '../../models';

@injectable()
class AdminGroupsRepository {
  async getAll(): Promise<Array<AdminGroup>> {
    try {
      const response = await axios.get(API_URL + 'groups');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async create(data: AdminGroupCreateRequest): Promise<AdminGroup> {
    try {
      const response = await axios.post(API_URL + 'groups', data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
export default AdminGroupsRepository;
