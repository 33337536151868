import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {useContext, useEffect} from 'react';
import {useFormik} from 'formik';
import authContext from '../../common/hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import {
  emailRegex,
  invalidEmailError,
  noMatchError,
  noPatternError,
  passwordRegex,
  requiredError,
} from '../../common/constants';

const NewPassword = () => {
  const {forgotPasswordSubmit} = useContext(authContext);
  const [saved, setSaved] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (saved) {
      navigate('/login');
    }
  }, [saved]);

  const formik = useFormik({
    initialValues: {
      email: '',
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async ({email, code, password}) => {
      try {
        await forgotPasswordSubmit(email, code, password);
        setSaved(true);
      } catch (error) {
        console.log(error);
      }
    },
    validate: values => {
      return {
        ...(!values.email && {email: requiredError}),
        ...(values.email &&
          !emailRegex.test(values.email) && {email: invalidEmailError}),
        ...(!values.code && {code: requiredError}),
        ...(!values.password && {password: requiredError}),
        ...(values.password &&
          !passwordRegex.test(values.password) && {password: noPatternError}),
        ...(!values.passwordConfirmation && {
          passwordConfirmation: requiredError,
        }),
        ...(values.passwordConfirmation &&
          values.passwordConfirmation !== values.password && {
            passwordConfirmation: noMatchError,
          }),
      };
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Nueva contraseña</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl id="email">
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  id="email"
                />
                {!!formik.errors.email && formik.touched.email ? (
                  <div style={{color: 'red', fontSize: 'small'}}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </FormControl>
              <FormControl id="code">
                <FormLabel>Código</FormLabel>
                <Input
                  type="code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  name="code"
                  id="code"
                />
              </FormControl>
              {!!formik.errors.code && formik.touched.code ? (
                <div style={{color: 'red', fontSize: 'small'}}>
                  {formik.errors.code}
                </div>
              ) : null}
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  id="password"
                />
              </FormControl>
              {!!formik.errors.password && formik.touched.password ? (
                <div style={{color: 'red', fontSize: 'small'}}>
                  {formik.errors.password}
                </div>
              ) : null}
              <FormControl id="password-confirmation">
                <FormLabel>Confirmar contraseña</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  name="passwordConfirmation"
                  id="password-confirmation"
                />
              </FormControl>
              {!!formik.errors.passwordConfirmation &&
              formik.touched.passwordConfirmation ? (
                <div style={{color: 'red', fontSize: 'small'}}>
                  {formik.errors.passwordConfirmation}
                </div>
              ) : null}
              <Stack>
                <Stack
                  direction={{base: 'column', sm: 'row'}}
                  align={'start'}
                  justify={'space-between'}
                ></Stack>
                <Button
                  disabled={formik.isSubmitting || !formik.isValid}
                  width="full"
                  type="submit"
                  bg="gray.900"
                  color="white"
                  colorScheme="blackAlpha"
                >
                  Enviar
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default NewPassword;
