import React from 'react';
import {RecordDetailsProps} from './interfaces';
import {IconStatus, setImages} from './utils';
import Moment from 'react-moment';
import {Tag} from '@chakra-ui/react';

export const Identity = ({record}: RecordDetailsProps) => {
  return (
    <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Email Validado</dt>
        <label className="mt-1 text-sm text-gray-400 block">Estatus</label>
        <dd className="mt-1 text-sm text-gray-900 inline-flex">
          <span className="text-sm text-gray-900 mr-8">
            {IconStatus(!!record.emailVerifiedAt)}
          </span>
        </dd>
      </div>
      <div className="sm:col-span-6">
        <dt className="text-sm font-medium text-gray-500">
          Identidad Validada
        </dt>
        <dt>
          <dd className="mt-1 text-sm text-gray-900 inline-flex">
            <span className="text-sm text-gray-900 mr-8">
              <label className="mt-1 text-sm text-gray-400 block">
                Estatus detalle
              </label>
              <Tag>
                {record.customer?.identification?.statusDetail
                  ? record.customer?.identification?.statusDetail
                  : '--'}
              </Tag>
            </span>
          </dd>
        </dt>
        <dt>
          <dd className="mt-1 text-sm text-gray-900 inline-flex">
            <span className="text-sm text-gray-900 mr-8">
              <label className="mt-1 text-sm text-gray-400 block">
                Estatus ID
              </label>
              {IconStatus(
                !!record.customer?.identification?.nominalListVerifiedAt
              )}
            </span>
            <span className="text-sm text-gray-900 mr-6">
              <label className="mt-1 text-sm text-gray-400 block">Fecha</label>
              {record.customer?.identification?.nominalListVerifiedAt && (
                <Moment
                  date={record.customer?.identification?.nominalListVerifiedAt}
                  format="DD-MM-YYYY hh:mm:ss"
                />
              )}
            </span>
            <span className="text-sm text-gray-900 mr-6">
              <label className="mt-1 text-sm text-gray-400 block">
                Imágenes ID
              </label>
              <div className="mt-1 space-x-4 text-gray-900 inline-flex">
                {setImages(
                  record.customer.identification?.images || [],
                  'FRONT_IMAGE',
                  false
                )}
                {setImages(
                  record.customer.identification?.images || [],
                  'BACK_IMAGE',
                  false
                )}
              </div>
            </span>
          </dd>
        </dt>
        <dt>
          <dd className="mt-1 text-sm text-gray-900 inline-flex">
            <span className="text-sm text-gray-900 mr-8">
              <label className="mt-1 text-sm text-gray-400 block">
                Estatus Selfie
              </label>
              {IconStatus(
                !!record.customer?.identification?.nominalListVerifiedAt
              )}
            </span>
            <span className="text-sm text-gray-900 mr-6">
              <label className="mt-1 text-sm text-gray-400 block">
                Imágenes Selfie
              </label>
              {setImages(
                record.customer.identification?.images || [],
                'FACE_IMAGE',
                false
              )}
            </span>
          </dd>
        </dt>
      </div>
      <div className="sm:col-span-6">
        <dt className="text-sm font-medium text-gray-500">CURP Validada</dt>
        <dd className="mt-1 text-sm text-gray-900 inline-flex">
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">
              Estatus CURP
            </label>
            {IconStatus(!!record.customer?.curpVerifiedAt)}
          </span>
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">CURP</label>
            <label className="mt-1 text-sm text-gray-700">
              {record.customer?.curp}
            </label>
          </span>
        </dd>
      </div>
      <div className="sm:col-span-6">
        <dt className="text-sm font-medium text-gray-500">RFC Validado</dt>
        <dd className="mt-1 text-sm text-gray-900 inline-flex">
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">
              Estatus RFC
            </label>
            {IconStatus(!!record.customer?.rfcVerifiedAt)}
          </span>
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">RFC</label>
            <label className="mt-1 text-sm text-gray-700">
              {record.customer?.rfc}
            </label>
          </span>
        </dd>
      </div>
      <div className="sm:col-span-6">
        <dt className="text-sm font-medium text-gray-500">Teléfono Validado</dt>
        <dd className="mt-1 text-sm text-gray-900 inline-flex">
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">
              Estatus Teléfono
            </label>
            {IconStatus(!!record.emailVerifiedAt)}
          </span>
          <span className="text-sm text-gray-900 mr-8">
            <label className="mt-1 text-sm text-gray-400 block">Teléfono</label>
            <label className="mt-1 text-sm text-gray-700">{record.phone}</label>
          </span>
        </dd>
      </div>
      <div className="sm:col-span-6">
        <dt className="text-sm font-medium text-gray-500">
          Direccion Validada
        </dt>
        <dd className="mt-1 text-sm text-gray-900 inline-flex">
          <span className="text-sm text-gray-900 mr-6">
            <label className="mt-1 text-sm text-gray-400 block">Estatus</label>
            {IconStatus(!!record.customer?.addressConfirmedAt)}
          </span>
          <span className="text-sm text-gray-900 mr-6">
            <label className="mt-1 text-sm text-gray-400 block">Fecha</label>
            <label className="mt-1 text-sm text-gray-700">
              {record.customer?.addressConfirmedAt}
            </label>
            {record.customer?.addressConfirmedAt && (
              <Moment
                date={record.customer?.addressConfirmedAt}
                format="DD-MM-YYYY hh:mm:ss"
              />
            )}
          </span>
        </dd>
      </div>
    </dl>
  );
};
