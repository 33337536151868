import React from 'react';
import {Badge, Button, useToast} from '@chakra-ui/react';
import {FaCreditCard, FaFileInvoiceDollar} from 'react-icons/fa';
import {Card, Credit} from '../../models';
import {UserDetailProps} from './interfaces';
import {getStatusColor} from '../../common/utils';
import {EditableField} from '../../common/components';
import {container} from '../../container';
import {UserActionsInterface} from '../../common/repositories/baseRepository.interface';
import {Types} from '../../types';
import {BaseActionsResponse} from '../../common/models/baseResponse';
import Moment from 'react-moment';

const UserDetail = ({user}: UserDetailProps) => {
  const toast = useToast();
  const userService = container.get<UserActionsInterface<BaseActionsResponse>>(
    Types.USER_REPOSITORY
  );
  const cardType = (digitalIndicator: boolean | undefined) =>
    digitalIndicator ? 'Digital' : 'Física';
  const onSubmitEmail = (value: string) => {
    userService
      .updateEmail({
        value: value,
        userId: user.id,
      })
      .then(() => {
        toast({
          title: 'Se actualizo al usuario',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          title: 'Error al actualizar al usuario',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      });
  };
  const onSubmitPhone = (value: string) => {
    userService
      .updatePhone({
        value: value,
        userId: user.id,
      })
      .then(() => {
        toast({
          title: 'Se actualizo al usuario',
          duration: 3000,
          isClosable: true,
          status: 'success',
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          title: 'Error al actualizar al usuario',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      });
  };
  const cardRow = (card: Card) => {
    return (
      <tr key={card.number}>
        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
          {cardType(card.digitalIndicator)}
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {cardMask(card.number)}
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {card.cardStatus?.status}
        </td>
      </tr>
    );
  };
  const creditApplicationsRow = (credit: Credit, key: number) => {
    return (
      <tr key={key}>
        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
          ${credit.creditLine}
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          ${credit.proposedLimit}
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          ${credit.theoreticalLimit}
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {credit.referenceId}
        </td>
      </tr>
    );
  };
  const cardMask = (cardNumber: string) => {
    return cardNumber.replace(/.(?=.{4})/g, '*');
  };
  const ifIsEmpty = (msg: string, items: JSX.Element[]) => {
    if (items) return items;

    return (
      <tr>
        <td className="px-3 py-4 text-sm text-gray-500">{msg}</td>
      </tr>
    );
  };

  return (
    <div className="grid py-5 gap-y-7">
      <dl className="px-5">
        <Button>Ver expediente</Button>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Customer ID</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user.customer.customerId}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Nombre</dt>
          <dd className="mt-1 text-sm text-gray-900">{user.customer.name}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Apellidos</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user.customer.lastName}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">CURP</dt>
          <dd className="mt-1 text-sm text-gray-900">{user.customer.curp}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            No. cliente Fiserv
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user.customer.customerNumber}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            No. cuenta Fiserv
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user.customer.account?.accountNumber}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">RFC</dt>
          <dd className="mt-1 text-sm text-gray-900">{user.customer.rfc}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Clabe</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {user.customer.account?.clabe}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Fecha de creación
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            <Moment
              date={user.customer.createdAt}
              format="DD-MM-YYYY hh:mm:ss"
            />
          </dd>
        </div>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 p-8 bg-gray-50">
        <EditableField
          onSubmit={onSubmitEmail}
          label="Correo"
          value={user.email}
        />
        <EditableField
          onSubmit={onSubmitPhone}
          label="Teléfono"
          value={user.phone}
        />
      </dl>
      <dl className="px-5">
        <div className="sm:col-span-6">
          <dt className="inline-flex text-sm font-medium text-gray-500">
            Crédito{' '}
            <FaFileInvoiceDollar className="ml-2 mt-1"></FaFileInvoiceDollar>
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Linea de credito
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-seNamelessmibold text-gray-900 lg:table-cell"
                  >
                    Limite propuesto
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Limite teorico
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Referencia/Token de pago
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {ifIsEmpty(
                  'No hay créditos',
                  user.customer.creditAnalysis?.map((credit: Credit, key) =>
                    creditApplicationsRow(credit, key)
                  ) || []
                )}
              </tbody>
            </table>
          </dd>
        </div>
        <div className="sm:col-span-6">
          <dt className="inline-flex text-sm font-medium text-gray-500">
            Tarjetas <FaCreditCard className="ml-2 mt-1"></FaCreditCard>
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Tipo
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Número
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {ifIsEmpty(
                  'No hay tarjetas',
                  user.customer.cards?.map((card: Card) => cardRow(card)) || []
                )}
              </tbody>
            </table>
          </dd>
        </div>
      </dl>
      <dl className="px-5">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Estatus de la cuenta
          </dt>
          <Badge colorScheme={getStatusColor(user.status)}>{user.status}</Badge>
        </div>
      </dl>
    </div>
  );
};

export default UserDetail;
