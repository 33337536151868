import React from 'react';
import {useFormik} from 'formik';

import {Button, Input, Box, Stack} from '@chakra-ui/react';
import {FiltersProps} from './interfaces';
import {placeHolders, initialValues} from './constants';

const Filters = ({onSubmit}: FiltersProps) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction={{base: 'column', md: 'row'}} width="full">
          <Box flexGrow="1">
            <Input
              type="text"
              name="id"
              id="id"
              placeholder={placeHolders.id}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.id}
            />
          </Box>
          <Box flexGrow="1">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder={placeHolders.name}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Box>
          <Box flexGrow="1">
            <Input
              name="last_name"
              id="last_name"
              type="text"
              placeholder={placeHolders.last_name}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.last_name}
            />
          </Box>
          <Box flexGrow="1">
            <Input
              type="email"
              name="email"
              id="email"
              placeholder={placeHolders.email}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Box>
          <Box flexGrow="1">
            <Input
              type="text"
              name="phone"
              id="phone"
              placeholder={placeHolders.phone}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </Box>
          <Box flexGrow="1">
            <Input
              type="text"
              name="curp"
              id="curp"
              placeholder={placeHolders.curp}
              focusBorderColor="gray.900"
              onChange={formik.handleChange}
              value={formik.values.curp}
            />
          </Box>
          <Box flexGrow="1">
            <Button
              width="full"
              isLoading={formik.isSubmitting}
              type="submit"
              bg="gray.900"
              color="white"
              colorScheme="blackAlpha"
            >
              Filtrar
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};
export default Filters;
