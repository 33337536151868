import axios from 'axios';
import {injectable} from 'inversify';
import {API_URL} from '../../settings';
import {toCamelCase, toSnakeCase} from '../../utils';
import {
  CollectionRepositoryInterface,
  OnboardingActionsInterface,
} from './baseRepository.interface';
import {BaseActionsResponse} from '../models/baseResponse';
import {UserListResponse} from '../models';
import GenerateUpdateIdLink from '../../pages/Onboardings/interfaces/generateUpdateIdLink';
import {
  UpdateIdLinkRequest,
  UpdateIdLinkResponse,
} from '../../pages/Onboardings/models';

@injectable()
class OnboardingRepository
  implements
    CollectionRepositoryInterface<UserListResponse>,
    OnboardingActionsInterface<BaseActionsResponse>,
    GenerateUpdateIdLink
{
  async create(data: UpdateIdLinkRequest): Promise<UpdateIdLinkResponse> {
    try {
      const response = await axios.post(
        API_URL + 'au10tix_link',
        toSnakeCase(data)
      );
      return toCamelCase(response.data) as UpdateIdLinkResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async getAll(query: {
    page: number;
    pageSize: number;
    filters: object;
  }): Promise<UserListResponse> {
    try {
      const response = await axios.get(API_URL + 'onboardings', {
        params: {
          page: query.page,
          ...query.filters,
        },
      });
      return toCamelCase(response.data) as UserListResponse;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  async updateCurp({
    value,
    userId,
  }: {
    value: string;
    userId: string;
  }): Promise<BaseActionsResponse> {
    try {
      const response = await axios.post(API_URL + 'users/update_curp', {
        user_id: userId,
        value,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateRfc({
    value,
    userId,
  }: {
    value: string;
    userId: string;
  }): Promise<BaseActionsResponse> {
    try {
      const response = await axios.post(API_URL + 'users/update_rfc', {
        user_id: userId,
        value,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
export default OnboardingRepository;
