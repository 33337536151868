const OpacityZero = {opacity: 0};
const OpacityFull = {opacity: 1};

const ChallengeNamesPaths = new Map([
  ['NEW_PASSWORD_REQUIRED', '/create-password'],
]);

// ReGex
const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

// Error messages
const noPatternError =
  'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial';
const noMatchError = 'Las contraseñas no coinciden';
const requiredError = 'Requerido';
const invalidEmailError = 'Email inválido';

export {
  OpacityZero,
  OpacityFull,
  ChallengeNamesPaths,
  passwordRegex,
  emailRegex,
  noPatternError,
  noMatchError,
  requiredError,
  invalidEmailError,
};
