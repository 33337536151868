import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, {useContext, useEffect} from 'react';
import {useFormik} from 'formik';
import authContext from '../../common/hooks/useAuth';
import {useNavigate} from 'react-router-dom';

const Login = () => {
  const {signIn, isAuthenticated, user, challengePath} =
    useContext(authContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(challengePath || '/users');
    }
  }, [isAuthenticated, user]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({email, password}) => {
      try {
        await signIn(email, password);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Inicia sesión</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl id="email">
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  id="email"
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  id="password"
                />
              </FormControl>
              <Stack>
                <Stack
                  direction={{base: 'column', sm: 'row'}}
                  align={'start'}
                  justify={'space-between'}
                ></Stack>
                <Button
                  width="full"
                  type="submit"
                  bg="gray.900"
                  color="white"
                  colorScheme="blackAlpha"
                >
                  Inicia sesión
                </Button>
              </Stack>
              <Stack className={'divide-y space-y-5'}>
                <Stack
                  direction={{base: 'column', sm: 'row'}}
                  align={'start'}
                  justify={'space-between'}
                ></Stack>
                <div className={'grid grid-flow-col text-center text-xs'}>
                  <Link
                    className={'col-span-2'}
                    color={'blue.300'}
                    href="/forgot-password"
                  >
                    Olvidé mi contraseña
                  </Link>
                  -
                  <Link
                    className={'col-span-2'}
                    color={'blue.300'}
                    href="/new-password"
                  >
                    Tengo un código
                  </Link>
                </div>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
