import {TableRowProps} from './interfaces';
import {Badge, Button, Flex, Td, Tr} from '@chakra-ui/react';
import {BsReverseLayoutSidebarInsetReverse} from 'react-icons/bs';
import {getCreditStatusColor} from '../../common/utils';
import React, {useState} from 'react';
import {Credit} from '../../models';
import TableItem from './TableItem';
import Moment from 'react-moment';
import {OpacityFull, OpacityZero} from '../../common/constants';

const TableRow = ({credit, onToggle, setCredit}: TableRowProps) => {
  const [buttonStyle, setButtonStyle] = useState(OpacityZero);
  const onMouseEnter = () => {
    setButtonStyle(OpacityFull);
  };
  const onMouseLeave = () => {
    setButtonStyle(OpacityZero);
  };

  const handleSidebarButtonOpen = (credit: Credit) => {
    onToggle();
    setCredit(credit);
  };

  return (
    <Tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Td>
        <Flex justifyContent="space-between">
          {credit.customer?.name}
          <Button
            leftIcon={<BsReverseLayoutSidebarInsetReverse />}
            style={buttonStyle}
            onClick={() => handleSidebarButtonOpen(credit)}
            colorScheme="gray"
            borderRadius="lg"
            size="xs"
            marginLeft="2"
            variant="outline"
          >
            Ver
          </Button>
        </Flex>
      </Td>
      <Td>
        <TableItem value={credit.customer?.lastName} />
      </Td>
      <Td>
        <TableItem value={credit.customer?.user?.email} />
      </Td>
      <Td>
        <Badge colorScheme={getCreditStatusColor(credit.status)}>
          {credit.status}
        </Badge>
      </Td>
      <Td>
        <Moment date={credit.createdAt} format="DD-MM-YYYY hh:mm:ss" />
      </Td>
      <Td>
        <TableItem value={credit.id} />
      </Td>
    </Tr>
  );
};

export default TableRow;
