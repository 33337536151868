import React from 'react';
import {Flex} from '@chakra-ui/react';
import {TableItemProps} from './interfaces';

const TableItem = ({value}: TableItemProps) => {
  return (
    <Flex>
      <span>{value}</span>
    </Flex>
  );
};

export default TableItem;
