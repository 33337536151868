import React from 'react';
import {usePagination} from './usePagination';
import {isString} from 'lodash';
import {Button, Text} from '@chakra-ui/react';

interface PaginationRangeProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: Function;
}

const PaginationRange = ({
  totalCount,
  pageSize,
  currentPage,
  setCurrentPage,
}: PaginationRangeProps) => {
  const paginationRange = usePagination(totalCount, pageSize, currentPage);
  return (
    <>
      {paginationRange.map((pageNumber, key) => {
        if (isString(pageNumber)) {
          return <Text key={key}>{pageNumber}</Text>;
        }
        return (
          <Button
            variant="ghost"
            key={key}
            onClick={() => setCurrentPage(pageNumber)}
            style={{fontWeight: currentPage === pageNumber ? 'bold' : 'normal'}}
          >
            {pageNumber}
          </Button>
        );
      })}
    </>
  );
};

export default PaginationRange;
