import React from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {CollectionRepositoryInterface} from '../../common/repositories/baseRepository.interface';
import {container} from '../../container';
import {Types} from '../../types';
import {UserListResponse} from '../../common/models';
import {User} from '../../models';
import Filters from './Filters';
import Table from './Table';
import {Pagination} from './../../common/components';
import {FilterValues} from './interfaces';
import UserDetail from './UserDetail';

const Users = () => {
  const pageSize = 20;
  const [page, setCurrentPage] = React.useState(1);
  const [filters, setFilters] = React.useState({});
  const [user, setUser] = React.useState<User | null>(null);
  const {isOpen, onClose, onToggle} = useDisclosure();

  const userService = container.get<
    CollectionRepositoryInterface<UserListResponse>
  >(Types.USER_REPOSITORY);

  const {isLoading, isFetching, error, data} = useQuery(
    ['users', page, filters],
    () => userService.getAll({page, filters, pageSize}),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const onFiltersSubmit = (values: FilterValues) => {
    setFilters(values);
  };
  const users: Array<User> = data?.items || [];
  const totalCount = data?.total || 0;
  const hasPrevPage = page > 1;
  const hasNextPage = data?.next !== null;
  if (isLoading || isFetching) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Spinner />
        </Center>
      </Flex>
    );
  } else if (error) {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Center flexGrow="1">
          <Heading fontWeight="light" fontSize="3xl">
            Error
          </Heading>
        </Center>
      </Flex>
    );
  } else {
    return (
      <Flex flexGrow="1" flexDirection="column">
        <Filters onSubmit={onFiltersSubmit} />
        <Table users={users} onToggle={onToggle} setUser={setUser} />
        <Pagination
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={page}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          setCurrentPage={setCurrentPage}
        />
        {user && (
          <Drawer onClose={onClose} isOpen={isOpen} size="xl">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>
                <Heading fontWeight="light" fontSize="3xl">
                  Usuario
                </Heading>
              </DrawerHeader>
              <DrawerBody>
                <UserDetail user={user} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </Flex>
    );
  }
};

export default Users;
