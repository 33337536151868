const placeHolders = {
  email: 'Correo',
  phone: 'Teléfono',
  curp: 'CURP',
  createdAt: 'Fecha de creación',
  updatedAt: 'Fecha de actualización',
};
const initialValues = {
  email: '',
  phone: '',
  curp: '',
  createdAt: '',
  updatedAt: '',
};
export {placeHolders, initialValues};
