import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {SENTRY_DNS, STAGE} from './settings';

import {Amplify} from 'aws-amplify';
// eslint-disable-next-line node/no-unpublished-import
import awsExports from './aws-exports';
import {AuthProvider} from './common/hooks/useAuth';

Amplify.configure(awsExports);

Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: STAGE,
});

const theme = extendTheme({
  colors: {
    brand: {
      commonBg: '#F8F8F8',
      sidebarBg: '#191A1A',
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ChakraProvider>
);

reportWebVitals();
