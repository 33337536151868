import React from 'react';
import {TableProps} from './interfaces';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';

const UsersTable = ({users, onToggle, setUser}: TableProps) => {
  return (
    <Box>
      <TableContainer paddingY="4">
        <Table backgroundColor="white">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Nombres</Th>
              <Th>Apellidos</Th>
              <Th>Correo</Th>
              <Th>Télefono</Th>
              <Th>Número de cuenta</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map(user => (
              <TableRow
                key={user.id}
                user={user}
                onToggle={onToggle}
                setUser={setUser}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default UsersTable;
