import {TableRowProps} from './interfaces';
import {Badge, Button, Flex, Td, Tr} from '@chakra-ui/react';
import {BsReverseLayoutSidebarInsetReverse} from 'react-icons/bs';
import React, {useState} from 'react';
import {Credit, User} from '../../models';
import TableItem from './TableItem';
import Moment from 'react-moment';
import {IoCheckbox} from 'react-icons/io5';
import {FaMinusCircle} from 'react-icons/fa';
import {AiOutlineLine} from 'react-icons/ai';
import {GrClose} from 'react-icons/gr';
import {OpacityFull, OpacityZero} from '../../common/constants';

const TableRow = ({record, onToggle, setRecord}: TableRowProps) => {
  const [buttonStyle, setButtonStyle] = useState(OpacityZero);
  const onMouseEnter = () => {
    setButtonStyle(OpacityFull);
  };
  const onMouseLeave = () => {
    setButtonStyle(OpacityZero);
  };

  const handleSidebarButtonOpen = (record: User) => {
    onToggle();
    setRecord(record);
  };

  const IconStatus = (status: boolean | null) => {
    switch (status) {
      case true:
        return <IoCheckbox style={{color: '#2E9E23'}} size="1.5rem" />;
      case false:
        return <FaMinusCircle style={{color: '#C1111C'}} size="1.5rem" />;
      case null:
        return <AiOutlineLine size="1.5rem" />;
      default:
        return <GrClose style={{color: '#C1111C'}} size="1.5rem" />;
    }
  };

  const getEvaluationStatus = (evaluations: Array<Credit>) => {
    if (evaluations.length === 0) {
      return IconStatus(null);
    } else {
      const moreRecentEvaluation = evaluations.reduce((prev, current) =>
        prev.createdAt > current.createdAt ? prev : current
      );
      let color = 'gray';
      if (
        moreRecentEvaluation.status.includes('ACEPTADO') ||
        moreRecentEvaluation.status.includes('approved')
      ) {
        color = 'green';
      } else if (
        moreRecentEvaluation.status.includes('RECHAZADO') ||
        moreRecentEvaluation.status.includes('rejected')
      ) {
        color = 'yellow';
      }
      return <Badge colorScheme={color}>{moreRecentEvaluation.status}</Badge>;
    }
  };

  return (
    <Tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Td>
        <Flex justifyContent="space-between">
          <TableItem value={record.email} />
          <Button
            leftIcon={<BsReverseLayoutSidebarInsetReverse />}
            style={buttonStyle}
            onClick={() => handleSidebarButtonOpen(record)}
            colorScheme="gray"
            borderRadius="lg"
            size="xs"
            marginLeft="2"
            variant="outline"
          >
            Ver
          </Button>
        </Flex>
      </Td>
      <Td>
        {record.customer?.createdAt && (
          <Moment
            date={record.customer.createdAt}
            format="DD-MM-YYYY hh:mm:ss"
          />
        )}
      </Td>
      <Td>
        <TableItem value={record.customer?.curp} />
      </Td>
      <Td>
        <TableItem value={record.phone} />
      </Td>
      <Td>
        <span>{IconStatus(record.secretKey)}</span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.emailVerifiedAt)}</span>
      </Td>
      <Td>
        <span>
          {IconStatus(!!record.customer?.identification?.nominalListVerifiedAt)}
        </span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.customer?.curpVerifiedAt)}</span>
      </Td>
      <Td>
        <span>{IconStatus(!record.customer?.blacklisted)}</span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.phoneVerifiedAt)}</span>
      </Td>
      <Td>
        <span>
          {getEvaluationStatus(record.customer?.creditAnalysis || [])}
        </span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.customer?.addressConfirmedAt)}</span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.customer?.contract?.contractPath)}</span>
      </Td>
      <Td>
        <span>{IconStatus(!!record.customer?.account?.accountNumber)}</span>
      </Td>
      <Td>
        {record.customer?.updatedAt && (
          <Moment
            date={record.customer.updatedAt}
            format="DD-MM-YYYY hh:mm:ss"
          />
        )}
      </Td>
    </Tr>
  );
};

export default TableRow;
