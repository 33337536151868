import {TableRowProps} from './interfaces';
import {Badge, Button, Flex, Td, Tr} from '@chakra-ui/react';
import {BsReverseLayoutSidebarInsetReverse} from 'react-icons/bs';
import {getStatusColor} from '../../common/utils';
import React, {useState} from 'react';
import {User} from '../../models';
import TableItem from './TableItem';
import Moment from 'react-moment';
import {OpacityFull, OpacityZero} from '../../common/constants';

const TableRow = ({user, onToggle, setUser}: TableRowProps) => {
  const [buttonStyle, setButtonStyle] = useState(OpacityZero);
  const onMouseEnter = () => {
    setButtonStyle(OpacityFull);
  };
  const onMouseLeave = () => {
    setButtonStyle(OpacityZero);
  };

  const handleSidebarButtonOpen = (user: User) => {
    onToggle();
    setUser(user);
  };

  return (
    <Tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Td>
        <Flex justifyContent="space-between">
          {user.customer.customerId}
          <Button
            leftIcon={<BsReverseLayoutSidebarInsetReverse />}
            style={buttonStyle}
            onClick={() => handleSidebarButtonOpen(user)}
            colorScheme="gray"
            borderRadius="lg"
            size="xs"
            marginLeft="2"
            variant="outline"
          >
            Ver
          </Button>
        </Flex>
      </Td>
      <Td>
        <TableItem value={user.customer.name} />
      </Td>
      <Td>
        <TableItem value={user.customer.lastName} />
      </Td>
      <Td>
        <TableItem value={user.email} />
      </Td>
      <Td>
        <TableItem value={user.phone} />
      </Td>
      <Td>
        <TableItem value={user.customer.curp} />
      </Td>
      <Td>
        <TableItem value={user.customer.account?.accountNumber || null} />
      </Td>
      <Td>
        <Badge colorScheme={getStatusColor(user.status)}>{user.status}</Badge>
      </Td>
      <Td>
        <Moment date={user.customer.createdAt} format="DD-MM-YYYY hh:mm:ss" />
      </Td>
    </Tr>
  );
};

export default TableRow;
