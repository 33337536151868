import React from 'react';
import {CreditApplicationDetailProps} from './interfaces';
import Moment from 'react-moment';
import {Badge, Button} from '@chakra-ui/react';
import {getCreditStatusColor, moneyFormat} from '../../common/utils';

const CreditApplicationDetail = ({credit}: CreditApplicationDetailProps) => {
  return (
    <div className="grid py-5 gap-y-7">
      <dl className="px-5">
        <Button>Aprobar manualmente</Button>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Nombre</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.customer?.name}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Apellidos</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.customer?.lastName + ' ' + credit.customer?.otherLastName}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Correo</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.customer?.user?.email}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Fecha y Hora</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.createdAt && (
              <Moment format="DD-MM-YYYY HH:mm">{credit.createdAt}</Moment>
            )}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Folio</dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.id}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Estatus</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <Badge colorScheme={getCreditStatusColor(credit.status)}>
              {credit.status}
            </Badge>
          </dd>
        </div>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 p-8 bg-gray-50">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Hit buró crédito:
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.customer?.blacklisted ? 'Si' : 'No'}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Versión modelo:</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.version + ' V'}
          </dd>
        </div>
      </dl>
      <dl className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6 px-5">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Límite propuesto
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {moneyFormat(credit.proposedLimit)}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Límite teórico</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {moneyFormat(credit.theoreticalLimit)}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            ISC (Score interno del modelo)
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.isc}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            BSC (Score Buró)
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.bsc}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            FS1 (Score definitivo)
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.fs1}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">ICC</dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.icc}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Ingreso estimado
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.estimatedIncomeBc}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Ingreso IMSS</dt>
          <dd className="mt-1 text-sm text-gray-900"></dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Cuentas actuales
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.totalCurrentAccounts}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Cuentas revolventes abiertas
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.totalRevolvingAccOpened}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Solicitudes 6M</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.buroRequestLastSixMonths}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Cuentas negativas
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.negativeAccounts}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">MOP 01+03</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.mopOneTwoThree}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Cuentas pago fijo
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.fixedPaymentsAccounts}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">MOP 96-99</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {credit.mopNine6Nine7Nine9}
          </dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">PCT LIM</dt>
          <dd className="mt-1 text-sm text-gray-900">{credit.pctLim + '%'}</dd>
        </div>
      </dl>
    </div>
  );
};

export default CreditApplicationDetail;
