import {TableRowProps} from './interfaces';
import {Td, Tr} from '@chakra-ui/react';
import React from 'react';
import TableItem from './TableItem';
import Moment from 'react-moment';

const TableRow = ({adminUser}: TableRowProps) => {
  return (
    <Tr>
      <Td>
        <TableItem value={adminUser.sub} />
      </Td>
      <Td>
        <TableItem value={adminUser.email} />
      </Td>
      <Td>
        <TableItem value={adminUser.user_status} />
      </Td>
      <Td>
        <Moment date={adminUser.user_create_date} format="DD-MM-YYYY" />
      </Td>
      <Td>
        <TableItem value={String(adminUser.enabled)} />
      </Td>
      <Td>
        {adminUser.groups.map((group, key) => (
          <TableItem key={key} value={group.group_name} />
        ))}
      </Td>
    </Tr>
  );
};

export default TableRow;
