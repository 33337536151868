import React, {useState} from 'react';

import {Flex, IconButton, useClipboard} from '@chakra-ui/react';
import {TableItemProps} from './interfaces';
import {HiOutlineClipboardCopy} from 'react-icons/hi';
import {OpacityFull, OpacityZero} from '../../common/constants';

const TableItem = ({value}: TableItemProps) => {
  const [buttonStyle, setButtonStyle] = useState(OpacityZero);
  const {hasCopied, onCopy} = useClipboard(value || '');
  const onMouseEnter = () => {
    setButtonStyle(OpacityFull);
  };
  const onMouseLeave = () => {
    setButtonStyle(OpacityZero);
  };

  return (
    <Flex
      justifyContent="space-between"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>{value}</span>
      {value && (
        <IconButton
          icon={<HiOutlineClipboardCopy />}
          onClick={onCopy}
          aria-label="copy"
          style={buttonStyle}
          colorScheme={hasCopied ? 'blue' : 'gray'}
          borderRadius="lg"
          size="xs"
          marginLeft="2"
          variant="outline"
        />
      )}
    </Flex>
  );
};

export default TableItem;
