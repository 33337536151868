import React from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import SidebarContent from './SidebarContent';
import MobileNav from './MobileNav';
import {Outlet, useLocation} from 'react-router-dom';
import {LinkItems} from './constants';

const sidebarFullSize = 60;
const sideBarMinSize = 14;
const commonPadding = {base: 2, md: 6};

const SidebarWithHeader = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {isOpen: sidebarFullIsOpen, onToggle: sidebarFullToggle} =
    useDisclosure({defaultIsOpen: false});
  const location = useLocation();
  const navBarTitle =
    LinkItems.find(el => el.page === location.pathname)?.text || '';
  const sideBarWidth = sidebarFullIsOpen ? sidebarFullSize : sideBarMinSize;
  const commonMargin = {base: 0, md: sideBarWidth};

  return (
    <Box minHeight="100vh" bg="brand.commonBg">
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        returnFocusOnClose={false}
        onClose={onClose}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} w="full" />
        </DrawerContent>
      </Drawer>
      <SidebarContent
        display={{base: 'none', md: 'block'}}
        width={sideBarWidth}
        sidebarFullToggle={sidebarFullToggle}
        sidebarFullIsOpen={sidebarFullIsOpen}
      />
      <Flex
        marginLeft={commonMargin}
        paddingX={commonPadding}
        height="full"
        flexDirection="column"
        minHeight="100vh"
      >
        <MobileNav
          height="20"
          alignItems="center"
          justifyContent="space-between"
          onOpen={onOpen}
          title={navBarTitle}
        />
        <Outlet />
      </Flex>
    </Box>
  );
};
export default SidebarWithHeader;
