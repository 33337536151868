import 'reflect-metadata';
import {Types} from './types';
import {Container} from 'inversify';
import {
  AdminUsersRepository,
  CreditRepository,
  UserRepository,
  AdminGroupsRepository,
  OnboardingRepository,
  CashBackRepository,
} from './common/repositories';

const container = new Container();

container.bind(Types.USER_REPOSITORY).to(UserRepository).inSingletonScope();
container.bind(Types.CREDIT_REPOSITORY).to(CreditRepository).inSingletonScope();
container
  .bind(Types.ONBOARDING_REPOSITORY)
  .to(OnboardingRepository)
  .inSingletonScope();
container
  .bind(Types.ADMIN_USER_REPOSITORY)
  .to(AdminUsersRepository)
  .inSingletonScope();
container
  .bind(Types.GROUP_REPOSITORY)
  .to(AdminGroupsRepository)
  .inSingletonScope();
container
  .bind(Types.CASHBACK_REPOSITORY)
  .to(CashBackRepository)
  .inSingletonScope();

export {container};
