import axios from 'axios';
import {injectable} from 'inversify';
import {API_URL} from '../../settings';
import {AdminUserCreateRequest} from '../models';
import {AdminUser} from '../../models';

@injectable()
class AdminUsersRepository {
  async getAll(query: {filters: object}): Promise<Array<AdminUser>> {
    try {
      const response = await axios.get(API_URL + 'admin_users', {
        params: {
          ...query.filters,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async create(data: AdminUserCreateRequest): Promise<AdminUser> {
    try {
      await axios.post(API_URL + 'admin_users', {
        email: data['email'],
      });
      const response = await axios.post(API_URL + '/groups/add_user', data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
export default AdminUsersRepository;
